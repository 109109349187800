<template>
    <div class="page">

        <Head />
        <Sidebar />
        <div class="body-main">
            <div class="container flex-1 scrollybg">
                <div class="flex-1 flex mb10">
                    <div class="flex-1">
                        <div class="idx-s idx-s1 mb10">
                            <div class="pub-tt m15">
                                <h5 class="bt">
                                    <img src="../assets/images/idx_09.png" class="ico" alt="" />能源概览<span
                                        class="year_num">({{ new Date().getFullYear() }})</span>
                                </h5>
                                <div class="dix"></div>
                            </div>
                            <ul class="list">
                                <li v-for="(item, index) in engList" :key="index">
                                    <div class="flex-1">
                                        <p class="fz1">{{ item.name }}</p>
                                        <p class="fz2">
                                            <i>{{ item.value }}</i>{{ item.unit }}
                                        </p>
                                    </div>
                                    <img src="../assets/images/idx_13.png" v-if="index == 0" class="ico" alt="" />
                                    <img src="../assets/images/idx_12.png" v-if="index == 1" class="ico" alt="" />
                                    <img src="../assets/images/idx_11.png" v-if="index == 2" class="ico" alt="" />
                                    <img src="../assets/images/idx_10.png" v-if="index == 3" class="ico" alt="" />
                                </li>
                            </ul>
                        </div>
                        <div class="idx-s idx-s2 mb10">
                            <div class="pub-tt m15">
                                <h5 class="bt">
                                    <img src="../assets/images/idx_15.png" class="ico" alt="" />能源结构
                                </h5>
                                <div class="dix"></div>
                            </div>
                            <ul class="list">
                                <li>
                                    <img src="../assets/images/idx_16.png" class="ico" alt="" />
                                    <div class="flex-1">
                                        <p class="fz1">绿色能源供给</p>
                                        <p class="fz2">
                                            <i style="color: #00ff9c">{{
                                            engObjData.luSeDianLiGongJi
                                        }}</i>MW
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <img src="../assets/images/idx_17.png" class="ico" alt="" />
                                    <div class="flex-1">
                                        <p class="fz1">灰色能源供给</p>
                                        <p class="fz2">
                                            <i>{{ engObjData.huiSeDianLiGongJi }}</i>MW
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="idx-s idx-s3">
                            <div class="pub-tt m15">
                                <h5 class="bt">
                                    <img src="../assets/images/idx_18.png" class="ico" alt="" />能源趋势预测
                                </h5>
                                <div class="dix"></div>
                            </div>
                            <div class="chart" ref="chart1"></div>
                        </div>
                    </div>
                    <div class="idx-s idx-s4 flex-v">
                        <div class="titbox">TH-EaaS SYSTEM<br /></div>
                        <div class="topbox">
                            <div class="ai">
                                <img src="../assets/images/idx_29.png" class="icon" alt="" />
                                <div class="voice-input-button-wrapper">
                                    <voice-input-button appId="efcc92ff" apiKey="625c9c2331ce58586f2eb47a278e2f31"
                                        apiSecret="ZDM3NTBhZTE2NTY5MDRjYjdiYTE0NDRi" v-model="result"
                                        @record="showResult" @record-start="recordStart" @record-stop="recordStop"
                                        @record-blank="recordNoResult" @record-failed="recordFailed"
                                        @record-ready="recordReady" @record-complete="recordComplete"
                                        interactiveMode="touch" color="#fff" tipPosition="top">
                                        <template slot="no-speak">没听清您说的什么</template>
                                    </voice-input-button>
                                </div>
                            </div>
                            <el-popover placement="bottom" trigger="manual" ref="popover" width="425"
                                :content="ai_result" v-model="aiFlag">
                                <el-input slot="reference" ref="headerSearchSelect" placeholder="请输入关键词"
                                    v-model="speack_result" @keyup.enter.native="speckChange" />
                            </el-popover>

                            <!-- <transition name="fade" v-if="aiFlag">
                                <div class="overlay" @click="closeDialog"></div>
                            </transition> -->
                        </div>
                        <div class="flex-1 picbox" @click="closeAi">
                            <img src="../assets/images/shouye.gif" class="img" alt="" />
                        </div>
                        <div class="slide">
                            <div class="swiper-container">
                                <div class="swiper-wrapper">
                                    <!-- <div class="swiper-slide" style="height: 80%" v-for="(item, index) in bannnerList"
                                        :key="index">
                                        <div class="item" v-if="item.pic != undefined">
                                            <img :src="item.pic" alt="" class="img" />
                                        </div>
                                    </div> -->
                                    <div class="swiper-slide" style="height: 80%" v-for="(item, index) in testPicList"
                                        :key="index">
                                        <div class="item">
                                            <img :src="item" alt="" class="img" />
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="swiper-button swiper-button-prev" v-if="hasPic"></div>
                                <div class="swiper-button swiper-button-next" v-if="hasPic"></div> -->
                                <div class="swiper-button swiper-button-prev"></div>
                                <div class="swiper-button swiper-button-next"></div>
                            </div>
                        </div>
                    </div>
                    <div class="flex-1">
                        <div class="idx-s idx-s5 mb10">
                            <div class="pub-tt m15">
                                <h5 class="bt">
                                    <img src="../assets/images/idx_19.png" class="ico" alt="" />能耗指标
                                </h5>
                                <div class="dix"></div>
                            </div>
                            <div class="ybp">
                                <div class="chart" ref="chart2"></div>
                                <div class="chart" ref="chart3"></div>
                            </div>
                            <div class="chart4" ref="chart4"></div>
                        </div>
                        <div class="idx-s idx-s6">
                            <div class="pub-tt m15">
                                <h5 class="bt">
                                    <img src="../assets/images/idx_21.png" class="ico" alt="" />告警指示
                                </h5>
                                <div class="dix"></div>
                            </div>
                            <vue-seamless-scroll :data="webData" class="scrollbox scrollybg"
                                :class-option="defaultOption">
                                <ul class="list">
                                    <li v-for="(item, index) in webData" :key="index">
                                        <template v-if="item.notifyLevel != 'Backup'">
                                            <img v-if="item.notifyLevel == 'Warning'" src="../assets/images/Warning.png"
                                                class="icon" alt="" />
                                            <img v-if="item.notifyLevel == 'Critical'"
                                                src="../assets/images/Critical.png" class="icon" alt="" />
                                            <img v-if="item.notifyLevel == 'Minor'" src="../assets/images/Minor.png"
                                                class="icon" alt="" />
                                            <img v-if="item.notifyLevel == 'Major'" src="../assets/images/Major.png"
                                                class="icon" alt="" />
                                            <div class="info">
                                                <p class="fz1 ellipsis">{{ item.deviceName }}</p>
                                                <p class="fz2 ellipsis" :title="item.content">
                                                    {{ item.content }}
                                                </p>
                                            </div>
                                        </template>
                                    </li>
                                </ul>
                            </vue-seamless-scroll>
                        </div>
                    </div>
                </div>
                <div class="idx-sec mb10 flex">
                    <div class="idx-s7">
                        <div class="item mb10">
                            <div class="item-t">
                                <img src="../assets/images/idx_22.png" class="icon" alt="" />
                                <div class="flex-1">
                                    <p class="fz">绿电交易额 <em class="tip"></em></p>
                                    <p class="num">126,560</p>
                                </div>
                            </div>
                            <div class="item-b">
                                <div class="wz">周同比 <em class="up">+12.5%</em></div>
                                <div class="wz">日环比 <em class="down">-13.3%</em></div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="item-t">
                                <img src="../assets/images/idx_22.png" class="icon" alt="" />
                                <div class="flex-1">
                                    <p class="fz">碳汇交易额 <em class="tip"></em></p>
                                    <p class="num">199,119</p>
                                </div>
                            </div>
                            <div class="item-b">
                                <div class="wz">周同比 <em class="up">+12.5%</em></div>
                                <div class="wz">日环比 <em class="down">-13.3%</em></div>
                            </div>
                            <div class="e">日均交易额 <span>12,336</span></div>
                        </div>
                    </div>
                    <div class="idx-s8 flex-1">
                        <div class="chart" ref="chart5"></div>
                    </div>
                    <div class="idx-s9">
                        <div class="item mb10 flex flex-align-center">
                            <img src="../assets/images/idx_25.png" class="icon" alt="" />
                            <div class="flex-1">
                                <p class="fz">转化率</p>
                                <p class="num">79%</p>
                            </div>
                            <div style="margin-right: 18px">
                                <el-progress type="circle" :percentage="25" :width="68" :show-text="false"
                                    define-back-color="#333861"></el-progress>
                            </div>
                            <span class="tip"></span>
                        </div>
                        <div class="item">
                            <div class="flex flex-align-center">
                                <img src="../assets/images/idx_26.png" class="icon" alt="" />
                                <div class="flex-1">
                                    <p class="fz">成功率</p>
                                    <p class="num">90%</p>
                                </div>
                            </div>
                            <div class="progressbar">
                                <div style="width: 100%">
                                    <el-progress :percentage="80" :show-text="false"></el-progress>
                                </div>
                            </div>
                            <div class="item-b">
                                <div class="wz">周同比 <em class="up">+12.5%</em></div>
                                <div class="wz">日环比 <em class="down">-13.3%</em></div>
                            </div>
                            <span class="tip"></span>
                        </div>
                    </div>
                </div>
                <div class="idx-s10 flex">
                    <span class="wz">碳中和进度</span>
                    <div class="flex-11 progressbar">
                        <div style="width: 100%">
                            <el-progress :percentage="engObjData.tanZhongHeJinDu"></el-progress>
                        </div>
                    </div>
                    <span class="num"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Head from "@/components/head.vue";
import Sidebar from "@/components/sidebar.vue";
let Echarts = require("echarts/lib/echarts");
import voiceInputButton from "voice-input-button2";
import ReconnectingWebSocket from "reconnecting-websocket";
import Swiper from "swiper";
import { indexInfo, lastAndThisInfo, trendInfo, MonitorInfo } from "@/api/home";
import { v4 as uuidv4 } from "uuid";
import { downloadPCM, downloadWAV } from "@/utils/chatUtils/download";
import CryptoJS from "crypto-js";
import Enc from "enc";
import VConsole from "vconsole";
import { Base64 } from "js-base64";
export default {
    data() {
        return {
            chart1: null,
            chart4: null,
            chart5: null,
            aiFlag: false,
            searchInput: "",
            result: "",
            speack_result: "",
            ai_result: "",
            aiLoading: true,
            bannerList: [], //轮播图

            testPicList: [
                require("../assets/images/idx_31.png"),
                require("../assets/images/idx_32.png"),
                require("../assets/images/idx_33.png"),
                require("../assets/images/fadongji.png"),
            ], //临时用

            hasPic: "",
            engList: [], //能源信息
            engObjData: {}, //能源统计
            webData: [],
            webNewData: [],
            trendData: {},
            lastMonthData: {},
            MonthData: {},

            websocket: null, // WebSocket对象
            transWorker: null, //ai对话

            APPID: "efcc92ff",
            API_SECRET: "ZDM3NTBhZTE2NTY5MDRjYjdiYTE0NDRi",
            API_KEY: "625c9c2331ce58586f2eb47a278e2f31",
            status: "init",
            ttsWS: "",
            total_res: "",
            bigModel: "",
            btnText: "开始对话",

            dayLost: "",
            hourLost: "",
        };
    },
    components: {
        Head,
        Sidebar,
        voiceInputButton,
    },
    created() {
        this.getHomeInfo();
        this.getMonitorInfo();
        this.bannerList = sessionStorage.getItem("projectList");
        this.isHavePic();
        this.getHourInfo();
        // this.transWorker = new TransWorker();
    },
    computed: {
        defaultOption() {
            return {
                step: 0.5, // 数值越大速度滚动越快
                limitMoveNum: this.webData.length, // 开始无缝滚动的数据量 this.dataList.length
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 1, // 0向下 1向上 2向左 3向右
                openWatch: true, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
            };
        },
    },
    mounted() {
        this.init1();
        this.init2();
        this.init3();
        this.init5();
        //当在任一路由页面被刷新时,便是根组件app被从新建立,此时能够进行webSocket重连
        window.addEventListener("resize", () => {
            this.chart1.resize();
            this.chart4.resize();
            this.chart5.resize();
        });

        this.autoBanner();
        // this.createWebsocket();
        this.updateData();
        this.getHourInfo();
        // 定时更新数据
        setInterval(() => {
            if (this.chart) {
                this.updateData();
            }
        }, 600000);
    },
    methods: {
        closeAi() {
            if (this.aiFlag === true) {
                this.aiFlag = false;
            }
        },
        isHavePic() {
            let banner = JSON.parse(this.bannerList);
            this.hasPic = banner.some((item) => item.hasOwnProperty("pic"));
        },
        getWebsocketUrl() {
            return new Promise((resolve, reject) => {
                var apiKey = this.API_KEY;
                var apiSecret = this.API_SECRET;
                var url = "wss://spark-api.xf-yun.com/v1.1/chat";
                const host = window.location.host;
                const date = new Date().toGMTString();
                const algorithm = "hmac-sha256";
                const headers = "host date request-line";
                const signatureOrigin = `host: ${host}\ndate: ${date}\nGET /v1.1/chat HTTP/1.1`;
                const signatureSha = CryptoJS.HmacSHA256(signatureOrigin, apiSecret);
                const signature = CryptoJS.enc.Base64.stringify(signatureSha);
                const authorizationOrigin = `api_key="${apiKey}", algorithm="${algorithm}", headers="${headers}", signature="${signature}"`;
                const authorization = window.btoa(authorizationOrigin);
                const finalUrl = `${url}?authorization=${authorization}&date=${date}&host=${host}`;
                resolve(finalUrl);
            });
        },

        // 连接websocket
        connectWebSocket() {
            return this.getWebsocketUrl().then((url) => {
                let ttsWS;
                if ("WebSocket" in window) {
                    ttsWS = new WebSocket(url);
                } else if ("MozWebSocket" in window) {
                    ttsWS = new MozWebSocket(url);
                } else {
                    alert("浏览器不支持WebSocket");
                    return;
                }
                var uuid = uuidv4().substr(0, 8);
                this.ttsWS = ttsWS;
                ttsWS.onopen = (e) => {
                    this.webSocketSend(uuid);
                };
                ttsWS.onmessage = (e) => {
                    this.aiResult(e.data);
                };
                ttsWS.onerror = (e) => {
                    clearTimeout(this.playTimeout);
                    alert("WebSocket报错，请f12查看详情");
                    console.error(
                        `详情查看：${encodeURI(url.replace("wss:", "https:"))}`
                    );
                };
                ttsWS.onclose = (e) => {
                    console.log(e);
                };
            });
        },
        // websocket发送数据
        webSocketSend(uuid) {
            var params = {
                header: {
                    app_id: this.APPID,
                    uid: uuid,
                },
                parameter: {
                    chat: {
                        domain: "general",
                        temperature: 0.5,
                        max_tokens: 4096,
                    },
                },
                payload: {
                    message: {
                        text: [
                            {
                                role: "user",
                                content: this.speack_result,
                            },
                        ],
                    },
                },
            };
            this.ttsWS.send(JSON.stringify(params));
        },
        start() {
            this.total_res = ""; // 请空回答历史
            this.aiFlag = true;
            this.ai_result = "正在努力思考回答，请稍后";

            this.connectWebSocket();
        },
        // websocket接收数据的处理
        aiResult(resultData) {
            let jsonData = JSON.parse(resultData);

            if (jsonData.header.code !== 0) {
                var errorMsg =
                    "ai对话接口错误，错误码：" + String(jsonData.header.code);
                alert(errorMsg);
                return;
            }

            this.total_res += jsonData.payload.choices.text[0].content;

            if (jsonData.header.code === 0 && jsonData.header.status === 2) {
                this.ai_result = this.total_res;
                this.ttsWS.close();
            }
        },
        speckChange() {
            this.start();
        },
        getMonitorInfo() {
            let param = {
                pageSize: 20,
                pageNum: 1,
                endStatus: 0,
            };
            MonitorInfo(param).then((res) => {
                if (res.data.code == 0) {
                    this.webData = res.data.rows;
                }
            });
        },
        // 创建 websocket 链接
        createWebsocket() {
            const httpURL = "43.143.218.144:9084/websocket";
            let token = sessionStorage.getItem("userToken");
            this.websocket = new ReconnectingWebSocket(
                `ws://${httpURL}?Authorization=${token}`
            );
            // 连接发生错误的回调方法
            this.websocket.onerror = this.websocketOnerror;
            // 连接成功建立的回调方法
            this.websocket.onopen = this.websocketOnopen;
            // 接收到消息的回调方法
            this.websocket.onmessage = this.websocketOnmessage;
            // 连接关闭的回调方法
            this.websocket.onclose = this.websocketOnclose;
            // 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
            this.websocket.onbeforeunload = this.websocketOnbeforeunload;
        },
        // 连接发生错误的回调方法
        websocketOnerror() {
            console.log("连接发生错误的回调方法");
        },
        // 连接成功建立的回调方法
        websocketOnopen() {
            console.log("连接成功建立的回调方法");
        },
        // 接收到消息的回调方法
        websocketOnmessage(event) {
            const data = JSON.parse(event.data);
            if (data.method == "alarmData" && data.NotifyLevel == "Backup") {
                this.webData = this.webData.filter((item) => {
                    return item.deviceId != data.deviceId;
                });
            } else if (data.method == "alarmData" && data.NotifyLevel != "Backup") {
                if (this.webData.length != 0) {
                    this.webData.splice(0, 1);
                    this.webData.unshift(data);
                } else {
                    this.webData.push(data);
                }
            }
        },
        // 连接关闭的回调方法
        websocketOnclose() {
            console.log("连接关闭的回调方法");
        },
        // 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常
        websocketOnbeforeunload() {
            this.closeWebSocket();
            console.log(
                "监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常"
            );
        },
        // 关闭WebSocket连接
        closeWebSocket() {
            this.websocket.close();
        },
        recordReady() {
            console.info("按钮就绪!");
        },
        recordStart() {
            this.total_res = "";
            console.info("录音开始");
        },
        showResult(text) {
            console.info("收到识别结果：", text);
        },
        recordStop() {
            console.info("录音结束");
        },
        recordNoResult() {
            console.info("没有录到什么，请重试");
        },
        recordComplete(text) {
            this.speack_result = text;
        },
        recordFailed(error) {
            console.info("识别失败，错误栈：", error);
        },
        //定时刷新图表数据
        updateData() {
            this.getTrendInfo();
        },
        /**
         * 获取首页统计信息
         */
        getHomeInfo() {
            let param = {
                projectId: Number(sessionStorage.getItem("projectId")),
            };
            indexInfo(param).then((res) => {
                if (res.data.code == 0) {
                    this.engList = res.data.rows[0].nengYuanGaiLanList;
                    this.engObjData = res.data.rows[0].nengYuanJieGou;
                    this.dayLost = res.data.rows[0].currentDay;
                    sessionStorage.setItem("day", this.dayLost);
                    this.hourLost = res.data.rows[0].lastHour;
                    sessionStorage.setItem("hour", this.hourLost);
                }
            });
        },
        /**
         * 能源趋势预测
         */
        getTrendInfo() {
            let param = {
                projectId: Number(sessionStorage.getItem("projectId")),
            };
            trendInfo(param).then((res) => {
                if (res.data.code == 0) {
                    let timeList = [];
                    let numList = [];
                    for (let i = 0; i < res.data.rows.length; i++) {
                        timeList.push(res.data.rows[i].time);
                        numList.push(res.data.rows[i].num);
                    }
                    this.trendData.time = timeList;
                    this.trendData.num = numList;

                    this.chart1.setOption({
                        xAxis: [
                            {
                                data: this.trendData.time,
                            },
                        ],
                        series: [
                            {
                                data: this.trendData.num,
                            },
                        ],
                    });
                }
            });
        },
        /**
         * 获取能耗指标
         */
        getHourInfo() {
            this.chart4 = Echarts.init(this.$refs.chart4);
            let param = {
                projectId: Number(sessionStorage.getItem("projectId")),
            };
            let lastTimeList = [];
            let nowTimeList = [];
            let lastNumList = [];
            let nowNumList = [];
            lastAndThisInfo(param).then((res) => {
                if (res.data.code == 0) {
                    for (let i = 0; i < res.data.rows[0].lastYear.length; i++) {
                        lastTimeList.push(
                            res.data.rows[0].lastYear[i].time.substring(5, 7)
                        );
                        lastNumList.push(res.data.rows[0].lastYear[i].num);
                    }
                    for (let i = 0; i < res.data.rows[0].currentYear.length; i++) {
                        nowTimeList.push(
                            res.data.rows[0].currentYear[i].time.substring(5, 7)
                        );
                        nowNumList.push(res.data.rows[0].currentYear[i].num);
                    }
                    this.lastMonthData.time = lastTimeList;
                    this.lastMonthData.num = lastNumList;
                    this.MonthData.time = nowTimeList;
                    this.MonthData.num = nowNumList;

                    let option = {
                        tooltip: {
                            trigger: "axis",
                            confine: true,
                        },
                        legend: {
                            icon: "circle",
                            x: "right",
                            top: "0",
                            itemWidth: 8,
                            itemHeight: 8,
                            itemGap: 15,
                            textStyle: {
                                color: "#ccd9e5",
                                fontSize: 12,
                            },
                        },
                        grid: {
                            left: 0,
                            right: 0,
                            bottom: 0,
                            top: 30,
                            containLabel: true,
                        },
                        xAxis: [
                            {
                                type: "category",
                                data: this.lastMonthData.time,
                                boundaryGap: true,
                                axisPointer: {
                                    type: "",
                                },
                                axisLabel: {
                                    show: true,
                                    textStyle: {
                                        color: "#9da9c0", //更改坐标轴文字颜色
                                        fontSize: 12, //更改坐标轴文字大小
                                        lineHeight: 12,
                                    },
                                    interval: 0,
                                },
                                splitLine: {
                                    lineStyle: {
                                        color: ["#e6d3c6"],
                                        width: 1,
                                        type: "dashed",
                                    },
                                },
                                axisTick: {
                                    show: true,
                                },
                            },
                        ],
                        yAxis: [
                            {
                                splitLine: {
                                    show: true,
                                    lineStyle: {
                                        color: ["rgba(255,255,255,.16)"],
                                        width: 1,
                                        type: "dashed",
                                    },
                                },
                            },
                        ],

                        series: [
                            {
                                name: "去年",
                                type: "line",
                                data: this.lastMonthData.num || [0],
                                smooth: false,
                                symbol: "circle",
                                symbolSize: 4,
                                itemStyle: {
                                    normal: {
                                        color: "#ae5da1",
                                        borderColor: "#ae5da1",
                                        borderWidth: 1,
                                        lineStyle: {
                                            color: "#ae5da1",
                                        },
                                    },
                                },
                            },
                            {
                                name: "今年",
                                type: "line",
                                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                                smooth: false,
                                symbol: "circle",
                                symbolSize: 4,
                                itemStyle: {
                                    normal: {
                                        color: "#008aff",
                                        borderColor: "#008aff",
                                        borderWidth: 1,
                                        lineStyle: {
                                            color: "#008aff",
                                        },
                                    },
                                },
                            },
                        ],
                    };
                    this.chart4.setOption(option);
                }
            });
        },
        autoBanner() {
            new Swiper(".slide .swiper-container", {
                loop: true,
                slidesPerView: 3,
                spaceBetween: 10,
                nextButton: ".swiper-button-next",
                prevButton: ".swiper-button-prev",
                observer: true,
                observeParents: true,
                watchOverflow: true,
            });
        },
        init1() {
            this.chart1 = Echarts.init(this.$refs.chart1);
            let option = {
                tooltip: {
                    trigger: "axis",
                    confine: true,
                    formatter: function (params, ticket, callback) {
                        var show = params[0].axisValue;
                        for (var i = 0, l = params.length; i < l; i++) {
                            show += "<br/>" + params[i].value + " kWh";
                        }
                        return show;
                    },
                },
                legend: {
                    icon: "circle",
                    x: "right",
                    top: "0",
                    itemWidth: 8,
                    itemHeight: 8,
                    itemGap: 15,
                    textStyle: {
                        color: "#ccd9e5",
                        fontSize: 12,
                    },
                },
                grid: {
                    left: 0,
                    right: 0,
                    bottom: 0,
                    top: 30,
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: "category",
                        data: [],
                        boundaryGap: true,
                        axisPointer: {
                            type: "",
                        },
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: "#9da9c0", //更改坐标轴文字颜色
                                fontSize: 12, //更改坐标轴文字大小
                                lineHeight: 12,
                            },
                            // rotate: "-45",
                            interval: 0,
                        },
                        axisLine: {
                            lineStyle: {
                                color: "rgba(255,255,255,.16)", //更改坐标轴颜色
                            },
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: ["#e6d3c6"],
                                width: 1,
                                type: "solid",
                            },
                        },
                        axisTick: {
                            show: true,
                        },
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                        name: "",
                        position: "left",
                        min: 0,
                        // max: 8,
                        // interval: 2,
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: "#7989a6", //更改坐标轴文字颜色
                                fontSize: 12, //更改坐标轴文字大小
                            },
                            formatter: "{value}kWh",
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: "#fff", //更改坐标轴颜色
                            },
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: ["rgba(255,255,255,.16)"],
                                width: 1,
                                type: "dashed",
                            },
                        },
                    },
                ],
                series: [
                    {
                        type: "bar",
                        data: [],
                        itemStyle: {
                            normal: {
                                color: new Echarts.graphic.LinearGradient(0, 1, 0, 0, [
                                    {
                                        offset: 0,
                                        color: "rgba(174,93,161,.5)",
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(174,93,161,1)",
                                    },
                                ]),
                            },
                        },
                    },
                ],
            };
            this.chart1.setOption(option);
        },
        init2() {
            this.chart = Echarts.init(this.$refs.chart2);
            var dataArr = sessionStorage.getItem("hour");
            var total = 1000;
            let option = {
                confine: true,
                series: [
                    {
                        name: "外部线",
                        type: "gauge",
                        radius: "98%", // 动态
                        startAngle: 225,
                        endAngle: -45,
                        axisLine: {
                            lineStyle: {
                                color: [
                                    [1, "rgba(42,46,85,.9)"], // 动态
                                ],
                                width: 30,
                            },
                        },
                        axisLabel: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                    {
                        name: "外部刻度",
                        type: "gauge",
                        radius: "92%",
                        min: 0, //最小刻度
                        max: total, //最大刻度
                        splitNumber: 10, //刻度数量
                        startAngle: 225,
                        endAngle: -45,
                        axisLine: {
                            show: false,
                            lineStyle: {
                                // color: [
                                //     [1, 'rgba(0,0,0,0)']
                                // ],
                                color: "#24284e",
                            },
                        },
                        //仪表盘轴线
                        axisLabel: {
                            show: false,
                            color: "red",
                            fontSize: 20, // 动态
                            distance: -20, // 动态
                        }, //刻度标签。
                        axisTick: {
                            show: false,
                        }, //刻度样式
                        splitLine: {
                            show: false,
                        },
                    },
                    //刻度
                    {
                        name: "间隔条形",
                        type: "gauge",
                        radius: "92%",
                        z: 4,
                        splitNumber: 50,
                        startAngle: 225,
                        endAngle: -45,
                        axisLine: {
                            lineStyle: {
                                opacity: 0,
                            },
                        },
                        axisLabel: {
                            show: false,
                        },
                        axisTick: {
                            show: true,
                            length: -18,
                            splitNumber: 1,
                            lineStyle: {
                                color: "#24284e",
                                width: 2.5,
                            },
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                    {
                        name: "数据",
                        type: "gauge",
                        radius: "90%",
                        z: 3,
                        startAngle: 225,
                        max: total,
                        endAngle: -45,
                        axisLine: {
                            lineStyle: {
                                color: [
                                    [dataArr / total, "#b756ff"], // 动态
                                    [1, "#32426c"],
                                ],

                                width: 20,
                            },
                        },
                        tooltip: {
                            show: false,
                        },
                        axisLabel: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        splitLine: {
                            show: false,
                        },
                        pointer: {
                            length: "80%",
                            width: 3,
                            offsetCenter: [0, "9%"],
                            itemStyle: {
                                color: "#e50ff7",
                            },
                        },
                        detail: {
                            value: dataArr,
                            fontSize: 18,
                            color: "#dba1ff",
                            offsetCenter: [0, "70%"],
                        },
                        data: [
                            {
                                value: dataArr,
                                name: "小时能耗（kwh)",
                                title: {
                                    offsetCenter: ["0%", "102%"],
                                    fontSize: 12,
                                    color: "#ccd9e5",
                                    show: true,
                                },
                            },
                        ],
                    },
                ],
            };
            this.chart.setOption(option);
        },

        init3() {
            this.chart = Echarts.init(this.$refs.chart3);

            var dataArr = sessionStorage.getItem("day");
            var total = 5000;
            let option = {
                confine: true,
                series: [
                    {
                        name: "外部线",
                        type: "gauge",
                        radius: "98%", // 动态
                        startAngle: 225,
                        endAngle: -45,
                        axisLine: {
                            lineStyle: {
                                color: [
                                    [1, "rgba(42,46,85,.9)"], // 动态
                                ],
                                width: 30,
                            },
                        },
                        axisLabel: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                    {
                        name: "外部刻度",
                        type: "gauge",
                        radius: "92%",
                        min: 0, //最小刻度
                        max: total, //最大刻度
                        splitNumber: 10, //刻度数量
                        startAngle: 225,
                        endAngle: -45,
                        axisLine: {
                            show: false,
                            lineStyle: {
                                // color: [
                                //     [1, 'rgba(0,0,0,0)']
                                // ],
                                color: "#24284e",
                            },
                        },
                        //仪表盘轴线
                        axisLabel: {
                            show: false,
                            color: "red",
                            fontSize: 20, // 动态
                            distance: -20, // 动态
                        }, //刻度标签。
                        axisTick: {
                            show: false,
                        }, //刻度样式
                        splitLine: {
                            show: false,
                        },
                    },
                    //刻度
                    {
                        name: "间隔条形",
                        type: "gauge",
                        radius: "92%",
                        z: 4,
                        splitNumber: 50,
                        startAngle: 225,
                        endAngle: -45,
                        axisLine: {
                            lineStyle: {
                                opacity: 0,
                            },
                        },
                        axisLabel: {
                            show: false,
                        },
                        axisTick: {
                            show: true,
                            length: -18,
                            splitNumber: 1,
                            lineStyle: {
                                color: "#24284e",
                                width: 2.5,
                            },
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                    {
                        name: "数据",
                        type: "gauge",
                        radius: "90%",
                        z: 3,
                        startAngle: 225,
                        max: total,
                        endAngle: -45,
                        axisLine: {
                            lineStyle: {
                                color: [
                                    [dataArr / total, "#0071de"], // 动态
                                    [1, "#32426c"],
                                ],

                                width: 20,
                            },
                        },
                        tooltip: {
                            show: false,
                        },
                        axisLabel: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        splitLine: {
                            show: false,
                        },
                        pointer: {
                            length: "80%",
                            width: 3,
                            offsetCenter: [0, "9%"],
                            itemStyle: {
                                color: "#64ddff",
                            },
                        },
                        detail: {
                            value: dataArr,
                            fontSize: 18,
                            color: "#008aff",
                            offsetCenter: [0, "70%"],
                        },
                        data: [
                            {
                                value: dataArr,
                                name: "日能耗（kwh)",
                                title: {
                                    offsetCenter: ["0%", "102%"],
                                    fontSize: 12,
                                    color: "#ccd9e5",
                                    show: true,
                                },
                            },
                        ],
                    },
                ],
            };
            this.chart.setOption(option);
        },
        init5() {
            this.chart5 = Echarts.init(this.$refs.chart5);
            let option = {
                tooltip: {
                    trigger: "axis",
                    confine: true,
                },
                legend: {
                    icon: "circle",
                    x: "center",
                    top: "0",
                    itemWidth: 8,
                    itemHeight: 8,
                    itemGap: 15,
                    textStyle: {
                        color: "#ccd9e5",
                        fontSize: 12,
                    },
                },
                grid: {
                    left: 10,
                    right: 10,
                    bottom: 0,
                    top: 45,
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: "category",
                        data: [
                            "09-14",
                            "09-15",
                            "09-16",
                            "09-17",
                            "09-18",
                            "09-19",
                            "09-20",
                            "09-21",
                            "09-22",
                            "09-23",
                            "09-24",
                            "09-25",
                            "09-26",
                            "09-27",
                            "09-28",
                        ],
                        boundaryGap: true,
                        axisPointer: {
                            type: "",
                        },
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: "#9da9c0", //更改坐标轴文字颜色
                                fontSize: 12, //更改坐标轴文字大小
                                lineHeight: 12,
                            },
                            rotate: 0,
                            interval: 0,
                        },
                        axisLine: {
                            lineStyle: {
                                color: "rgba(255,255,255,.16)", //更改坐标轴颜色
                            },
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: ["#e6d3c6"],
                                width: 1,
                                type: "solid",
                            },
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                        name: "(单位元/个）",
                        nameTextStyle: {
                            color: "#7989a6",
                            padding: [0, 0, 0, 15],
                            fontSize: 12,
                        },
                        position: "left",
                        min: 0,
                        // max: 8,
                        // interval: 2,
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: "#7989a6", //更改坐标轴文字颜色
                                fontSize: 12, //更改坐标轴文字大小
                            },
                            //formatter: '{value}%'
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: "#fff", //更改坐标轴颜色
                            },
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: ["rgba(255,255,255,.16)"],
                                width: 1,
                                type: "dashed",
                            },
                        },
                    },
                ],

                series: [
                    {
                        name: "用电",
                        type: "line",
                        data: [15, 25, 22, 30, 40, 25, 30, 35, 40, 25, 15, 20, 15, 25, 35],
                        smooth: true,
                        symbol: "circle",
                        symbolSize: 4,
                        itemStyle: {
                            normal: {
                                color: "#ae5da1",
                                borderColor: "#ae5da1",
                                borderWidth: 1,
                                lineStyle: {
                                    color: "#ae5da1",
                                },
                                areaStyle: {
                                    color: new Echarts.graphic.LinearGradient(0, 1, 0, 0, [
                                        {
                                            offset: 0,
                                            color: "rgba(174,93,161,0)",
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(174,93,161,.5)",
                                        },
                                    ]),
                                },
                            },
                        },
                    },
                    {
                        name: "光伏",
                        type: "line",
                        data: [25, 15, 20, 15, 26, 58, 60, 55, 45, 40, 30, 25, 35, 45, 30],
                        smooth: true,
                        symbol: "circle",
                        symbolSize: 4,
                        itemStyle: {
                            normal: {
                                color: "#008aff",
                                borderColor: "#008aff",
                                borderWidth: 1,
                                lineStyle: {
                                    color: "#008aff",
                                },
                                areaStyle: {
                                    color: new Echarts.graphic.LinearGradient(0, 1, 0, 0, [
                                        {
                                            offset: 0,
                                            color: "rgba(0,138,255,0)",
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(0,138,255,.5)",
                                        },
                                    ]),
                                },
                            },
                        },
                    },
                ],
            };
            this.chart5.setOption(option);
        },
    },
    destroyed() {
        this.closeWebSocket();
    },
};
</script>

<style scoped>
.container {
    width: calc(100% + 15px);
    padding-right: 15px;
}




.idx-s {
    padding: 0 20px 20px;
}

.pub-tt {
    margin: 0 0 10px;
}

.idx-s1 {
    background: url(../assets/images/idx_01.png) no-repeat center;
    background-size: 100% 100%;
}

.idx-s1 .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: -2%;
}

.idx-s1 .list li {
    background: url(../assets/images/idx_14.jpg) no-repeat center;
    background-size: 100% 100%;
    width: 48.5%;
    margin-top: 3%;
    display: flex;
    align-items: center;
    padding: 8px 15px;
    box-shadow: 0 0 6px rgba(171, 75, 254, .4);
}

.idx-s1 .list .fz1 {
    font-size: 14px;
    color: #ccd9e5;
}

.idx-s1 .list .fz2 {
    font-size: 12px;
    color: #7989a6;
    margin-top: 4px;
}

.idx-s1 .list .fz2 i {
    font-size: 26px;
    color: #fff;
    font-family: 'Digital';
    padding-right: 4px;
}

.idx-s1 .list .ico {
    width: 50px;
}



.idx-s2 {
    background: url(../assets/images/idx_02.png) no-repeat center;
    background-size: 100% 100%;
}

.idx-s2 .list {
    display: flex;
    justify-content: space-between;
}

.idx-s2 .list li {
    display: flex;
    align-items: center;
}

.idx-s2 .list .ico {
    width: 50px;
    margin-right: 10px;
}

.idx-s2 .list .fz1 {
    font-size: 14px;
    color: #ccd9e5;
}

.idx-s2 .list .fz2 {
    font-size: 12px;
    color: #7989a6;
    margin-top: 4px;
}

.idx-s2 .list .fz2 i {
    font-size: 26px;
    color: #fff;
    font-family: 'Digital';
    padding-right: 4px;
}


.idx-s3 {
    background: url(../assets/images/idx_03.png) no-repeat center;
    background-size: 100% 100%;
}

.idx-s3 .chart {
    height: 150px;
}


.idx-s4 {
    margin: 0 10px;
    width: 50%;
    background: url(../assets/images/idx_04.png) no-repeat center;
    background-size: 100% 100%;
    overflow: hidden;
    height: 605px;
}

.idx-s4 .titbox {
    width: 465px;
    height: 72px;
    background: url(../assets/images/idx_28.png) no-repeat center;
    background-size: 100% 100%;
    margin: 0 auto;
    font-size: 18px;
    color: #fff;
    text-align: center;
    padding: 9px 0 0;
    line-height: 1.5;
}

.idx-s4 .topbox {
    height: 40px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.idx-s4 .ai {
    position: relative;
    margin-right: 10px;
}

.idx-s4 .ai .icon {
    position: absolute;
    left: -35px;
    border-radius: 50%;
}

.idx-s4 .ai .fz {
    font-size: 16px;
    color: #fff;
    width: 115px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    border-radius: 3px;
    background: url(../assets/images/idx_35.png) no-repeat center;
    background-size: cover;
    display: block;
    padding-left: 15px;
}

.idx-s4 .topbox>>>.el-input {
    width: 420px;
}

.idx-s4 .picbox {
    margin: 10px 0;


}

.idx-s4 .picbox .img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    display: block;
    margin: 0 auto;
}


.idx-s4 .slide {
    padding: 0 35px;
    position: relative;
}

.idx-s4 .slide .item {
    height: 110px;
    background-color: #181a2e;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.idx-s4 .slide .item .img {
    max-width: 90%;
    max-height: 90%;
    width: auto;
    height: auto;
    transition: all .3s;
}

.idx-s4 .slide .item:hover .img {
    transform: scale(1.1, 1.1);
}

.idx-s4 .slide .swiper-container {
    position: static;
}

.idx-s4 .slide .swiper-button {
    width: 30px;
    height: 30px;
    background: url(../assets/images/idx_34.png) no-repeat center;
    background-size: contain;
    margin-top: -15px;
}

.idx-s4 .slide .swiper-button-prev {
    left: 0;
}

.idx-s4 .slide .swiper-button-next {
    right: 0;
    transform: rotate(180deg);
}

.idx-s4 .slide .swiper-button:hover {
    background-image: url(../assets/images/idx_34-1.png)
}

.idx-s5 {
    background: url(../assets/images/idx_05.png) no-repeat center;
    background-size: 100% 100%;
    /* height: 380px; */
}

.idx-s5 .pub-tt {
    margin-bottom: 10px;
}

.idx-s5 .chart4 {
    height: 140px;
}

.idx-s5 .ybp {
    display: flex;
    padding-bottom: 18px;
    justify-content: space-between;
}

.idx-s5 .ybp .grp {
    text-align: center;
    position: relative;
}

.idx-s5 .ybp .tp {
    height: 100px;
    width: auto;
}

.idx-s5 .ybp .num {
    font-size: 26px;
    font-family: 'Digital';
    margin-top: -8px;
}

.idx-s5 .ybp .fz {
    font-size: 14px;
    color: #ccd9e5;
    margin-top: 4px;
}

.idx-s5 .ybp .tip {
    width: 20px;
    height: 20px;
    background: url(../assets/images/idx_20.png) no-repeat center;
    background-size: contain;
    position: absolute;
    right: 0;
    top: 0;
}

.idx-s5 .ybp .chart {
    height: 110px;
    width: 50%;
}

.idx-s6 {
    background: url(../assets/images/idx_06.png) no-repeat center;
    background-size: 100% 100%;
    padding-bottom: 5px;
}

.idx-s6 .scrollbox {
    height: 180px;
}

.idx-s6 .list li {
    padding: 10px 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #2e2f3b;
}

.idx-s6 .list .icon {
    width: 44px;
    height: 44px;
    margin-right: 10px;
}

.idx-s6 .list .info {
    flex: 1;
    overflow: hidden;
}

.idx-s6 .list .fz1 {
    font-size: 14px;
    color: #ccd9e5;
}

.idx-s6 .list .fz2 {
    font-size: 13px;
    color: #7989a6;
    margin-top: 4px;
}

.idx-s6 .scrollbox {
    width: calc(100% + 10px);
    padding-right: 10px;
}

.idx-s6 .list li:last-child {
    border: none;
}

.idx-sec {
    background: url(../assets/images/idx_07.png) no-repeat center;
    background-size: 100% 100%;
    padding: 18px 20px;
}

.idx-s7 {
    width: 265px;
}

.idx-s7 .item {
    background: url(../assets/images/idx_27.jpg) no-repeat center;
    background-size: 100% 100%;
    padding: 10px 15px;
    box-shadow: 0 0 6px rgba(171, 75, 254, .4);
}

.idx-s7 .item-t {
    display: flex;
    align-items: center;
}

.idx-s7 .icon {
    width: 68px;
    margin-right: 10px;
}

.idx-s7 .fz {
    font-size: 14px;
    color: #ccd9e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.idx-s7 .tip {
    width: 20px;
    height: 20px;
    background: url(../assets/images/idx_20.png) no-repeat center;
    background-size: contain;
}

.idx-s7 .num {
    font-size: 26px;
    color: #fff;
    font-family: 'Digital';
    margin-top: 5px;
}

.idx-s7 .item-b {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.idx-s7 .wz {
    font-size: 12px;
    color: #ccd9e5;
}

.idx-s7 .wz em {
    font-size: 16px;
    font-family: 'Digital';
    margin-left: 3px;
}

.idx-s7 .wz .up {
    background: url(../assets/images/idx_23.png) no-repeat right center;
    background-size: 10px 13px;
    padding-right: 15px;
    color: #00ff9c;
}

.idx-s7 .wz .down {
    background: url(../assets/images/idx_24.png) no-repeat right center;
    background-size: 10px 13px;
    padding-right: 15px;
    color: #f01251;
}

.idx-s7 .e {
    font-size: 12px;
    color: #7989a6;
    margin-top: 8px;
}

.idx-s7 .e span {
    font-size: 16px;
    font-family: 'Digital';
    color: #fff;
    margin-left: 5px;
}

.idx-s8 {
    padding: 0 20px;
}

.idx-s8 .chart {
    height: 230px;
}

.idx-s9 {
    width: 265px;
}

.idx-s9 .item {
    background: url(../assets/images/idx_27.jpg) no-repeat center;
    background-size: 100% 100%;
    padding: 14px 15px;
    box-shadow: 0 0 6px rgba(171, 75, 254, .4);
    position: relative;
}

.idx-s9 .icon {
    width: 68px;
    margin-right: 6px;
}

.idx-s9 .fz {
    font-size: 14px;
    color: #ccd9e5;
}

.idx-s9 .num {
    font-size: 26px;
    color: #fff;
    font-family: 'Digital';
    margin-top: 5px;
}

.idx-s9 .progressbar {
    margin-top: 8px;
}

.idx-s9 .item-b {
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
}

.idx-s9 .wz {
    font-size: 12px;
    color: #ccd9e5;
}

.idx-s9 .wz em {
    font-size: 16px;
    font-family: 'Digital';
    margin-left: 3px;
}

.idx-s9 .wz .up {
    background: url(../assets/images/idx_23.png) no-repeat right center;
    background-size: 10px 13px;
    padding-right: 15px;
    color: #00ff9c;
}

.idx-s9 .wz .down {
    background: url(../assets/images/idx_24.png) no-repeat right center;
    background-size: 10px 13px;
    padding-right: 15px;
    color: #f01251;
}

.idx-s9 .tip {
    width: 20px;
    height: 20px;
    background: url(../assets/images/idx_20.png) no-repeat center;
    background-size: contain;
    position: absolute;
    right: 8px;
    top: 8px;
}


.idx-s10 {
    background: url(../assets/images/idx_08.png) no-repeat center;
    background-size: 100% 100%;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 30px;
}

.idx-s10 .wz {
    font-size: 14px;
    color: #ccd9e5;
    margin-right: 8px;
}

.idx-s10 .num {
    width: 60px;
}

.progressbar {
    border: 1px solid rgba(255, 255, 255, .26);
    border-radius: 6px;
    padding: 0 6px;
    height: 15px;
    display: flex;
    align-items: center;
}

.progressbar>>>.el-progress-bar__outer {
    background: #595e89 !important;
    overflow: initial;
}

.progressbar>>>.el-progress-bar__inner {
    background: linear-gradient(to right, #ab4bfe, #f5eaff) !important;
}

.progressbar>>>.el-progress-bar__inner::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: #fff;
    border-radius: 50%;
    right: 0;
    top: 50%;
    margin-top: -4px;
    box-shadow: 0 0 50px rgba(155, 8, 248, .9);
}

.progressbar>>>.el-progress__text {
    font-size: 20px !important;
    color: #fff !important;
    font-family: 'Digital';
    position: absolute;
    right: -50px;
    top: 50%;
    line-height: 30px;
    margin-top: -15px;
}

.progressbar>>>.el-progress-bar {
    margin-right: 0;
    padding-right: 0;
}
</style>
