import http from "../utils/http";
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
let resquest = "/apis";
// 首页统计【能源概览、能源结构】
export function indexInfo(params) {
  return http.post(`${resquest}/home/001-homeStatistics`, params);
}
// 能耗指标(本月和上月)
export function lastAndThisInfo(params) {
  return http.post(
    `${resquest}/home/002-nengHaoCurrentMonthAndLastMonth`,
    params
  );
}
// 能源趋势预测
export function trendInfo(params) {
  return http.post(`${resquest}/home/003-nengYuanQuSHiYuCe`, params);
}
// 告警记录
export function MonitorInfo(params) {
  return http.post(`${resquest}/cusMonitor/log/list`, params);
}
